<template>
  <div class="wrapper">
    <parallax class="section page-header header-filter" :style="headerStyle"></parallax>
    <div class="main main-raised">
      <div class="section profile-content">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-50 mx-auto">
              <div class="profile">
                <div class="avatar">
                  <img :src="img" alt="Circle Image" class="img-raised rounded-circle img-fluid">
                </div>
                <div class="name">
                  <h1 class="title h3">Clémence Bauche Sophrologue</h1>
                  <md-button
                    href="https://www.facebook.com/clemence.sophrologue/"
                    target="_blank"
                    rel="noopener"
                    class="md-just-icon md-simple"
                  >
                    <i class="fab fa-facebook-square"></i>
                  </md-button>
                  <md-button
                    href="https://www.linkedin.com/in/clémence-bauche-3ba825135"
                    target="_blank"
                    rel="noopener"
                    class="md-just-icon md-simple"
                  >
                    <i class="fab fa-linkedin"></i>
                  </md-button>
                </div>
              </div>
            </div>
          </div>
          <div class="description pb-5">
            <p>
              Après avoir obtenu mon Diplôme d’État en tant qu’infirmière en 2013, j'ai souhaité élargir mes compétences afin de proposer de nouvelles techniques de soins à mes patients. Pour cela, j'ai effectué une formation afin d’obtenir le diplôme de Sophrologue au sein de L’institut National de l’Enseignement de la Sophrologie à Paris (avec titre RNCP).
              Durant cette formation j'ai suivi 2 séminaires de spécialisation sur la gestion du stress et du sommeil.<br>
              Après avoir vécu et pratiqué la sophrologie pendant 6 ans a Montréal, durant lesquels je me suis également formée à l'EFT en 2020. J'effectue mes consultations en visioconférence de nouveau en France pour vous accompagner vous et votre famille afin d'avoir des outils concrets, faciles et rapides à mettre en place dans votre quotidien pour une meilleure connaissance de soi et une vie plus sereine.
            </p>
          </div>

          <div class="md-layout">
            <div class="elfsight-app-7a170906-fef3-433c-bb35-4756dd02077d"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  bodyClass: "profile-page",
  metaInfo: {
    title: "Clémence Bauche - sophrologue - Profil",
    meta: [
      {
        name: "description",
        content:
          "Clémence Bauche Sophrologue méthode caycedienne - Mon parcours et mes expériences en tant que sophrologue",
      },
    ],
    link: [
      { rel: "canonical", href: "https://clemence-sophrologie.fr/profil/" },
    ],
  },
  data() {
    return {};
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/nature.jpg"),
    },
    img: {
      type: String,
      default: require("@/assets/img/faces/clemence_bauche.jpg"),
    },
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.section
  padding: 0

@media (max-width: 991px)
  .profile-page
    .page-header  
      height: 260px
</style>
