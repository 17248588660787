import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import MaterialKit from "./plugins/material-kit";
import LoadScript from "vue-plugin-load-script";

Vue.config.productionTip = false;

Vue.use(MaterialKit);

const NavbarStore = {
  showNavbar: false
};

Vue.mixin({
  data() {
    return {
      NavbarStore
    };
  }
});

Vue.use(LoadScript);
Vue.loadScript("https://apps.elfsight.com/p/platform.js");

new Vue({
  el: "#app",
  router,
  render: h => h(App)
}).$mount("#app");
