<template>
  <div class="wrapper">
    <parallax class="page-header header-filter" :style="headerStyle">
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="image-wrapper">
            <img :src="leaf3" alt class="leaf3" v-show="leafShow" />
            <img :src="leaf1" alt class="leaf1" v-show="leafShow" />
            <div class="brand">
              <h1>Clémence Bauche</h1>
              <h3>Sophrologue méthode caycedienne</h3>
              <a
                href="https://squareup.com/appointments/book/jvxl0e0l0mthuc/L0MFD34JZ728M/services"
                target="_blank"
                rel="noopener"
                class="d-block"
              >
                <md-button class="md-success"
                  >Rendez-vous visioconférence</md-button
                >
              </a>
            </div>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <div class="md-layout">
            <!-- <div
              class="md-layout-item md-size-80 md-xsmall-size-100 mx-auto text-center"
            >
              <h2 class="text-success" style="font-weight:bold">
                Nouveau ! Pack émotions pour enfant de 3 à 8 ans !
              </h2>
              <p>
                <a
                  href="https://square.link/u/2PEwfJJy"
                  target="_blank"
                  rel="noopener"
                  class="d-block"
                >
                  <md-button class="md-success"
                    >En savoir plus</md-button
                  >
                </a>
              </p>
            </div> -->

            <div
              class="md-layout-item md-size-80 md-xsmall-size-100 mx-auto text-center"
            >
              <h2 class="text-success" style="font-weight:bold">
                Nouveau ! Test Haut Potentiel Sensible<br>du Dr Elaine Aron
              </h2>
              <p>Vous vous posez des questions sur vous, vous souhaitez en savoir plus sur le Haut Potentiel Sensible (hypersensibilité) ? Passez le test créée par le Dr Elaine Aron et ayez un compte rendu détaillé (analyse du questionnaire, outils et pistes à explorer personnalisées pour un quotidien plus doux). N'hésitez pas a me contacter pour toutes questions.</p>
              <p>
                <a
                  href="https://squareup.com/appointments/book/jvxl0e0l0mthuc/L0MFD34JZ728M/services"
                  target="_blank"
                  rel="noopener"
                  class="d-block"
                >
                  <md-button class="md-success"
                    >Prendre rendez-vous</md-button
                  >
                </a>
              </p>
            </div>

            <div
              class="md-layout-item md-size-80 md-xsmall-size-100 mx-auto text-center"
            >
              <h2 style="font-weight:bold">Qu'est-ce que la sophrologie ?</h2>
              <p class="h4">
                La sophrologie est une méthode de relaxation dynamique. La
                sophrologue vous accompagnera dans une détente physique et
                psychique par le biais d’exercices basés sur la respiration. La
                sophrologie permet de prendre conscience de la relation
                corps-esprit. Pratiquer la sophrologie vous aidera à mieux vous
                connaitre pour mieux vivre en accord avec vos valeurs.
              </p>
              <md-button href="/sophrologie/" class="md-simple md-info md-lg"
                >En savoir plus</md-button
              >
            </div>
          </div>

          <div class="md-layout">
            <div class="md-layout-item md-size-100 mx-auto">
              <div class="text-center">
                <h2 class="title">
                  Champs d'action de la sophrologie méthode caycedienne
                </h2>
              </div>
            </div>
          </div>

          <div class="md-layout custom-list-items">
            <div class="md-layout-item md-size-100 mx-auto">
              <tabs
                :tab-name="['Adultes', 'Enfants', 'Entreprises']"
                :tab-icon="['face', 'child_care', 'business']"
                plain
                nav-pills-icons
                color-button="info"
              >
                <!-- here you can add your content for tab-content -->
                <template slot="tab-pane-1">
                  <div>
                    <h3>Gestion du stress / burn out :</h3>
                    <p>
                      De nos jours, le stress côtoie notre quotidien. Métro,
                      boulot, dodo résume votre journée, vous vous sentez
                      épuisée au bord du burn out. N’attendez pas et faites-vous
                      aider. La sophrologie vous donnera les outils pour gérer
                      votre stress, mais aussi à vous redonner un nouveau
                      souffle de confiance en soi. Retrouvez une harmonie
                      corps-esprit dans votre quotidien et apprenez à gérer les
                      moments de crises d’angoisses.
                    </p>
                  </div>

                  <div>
                    <h3>
                      Gestion des troubles (sommeil, addictif, alimentaire,
                      concentration) :
                    </h3>
                    <p>
                      Peu importe l’âge, certains troubles peuvent venir gâcher
                      votre quotidien. La sophrologie peut vous accompagner afin
                      de les gérer, les diminuer voir de les arrêter. Vous
                      souhaitez arrêter de fumer, avoir de l’aide supplémentaire
                      pour perdre ou gagner du poids ou encore tout simplement
                      mieux dormir, la sophrologie vous accompagne grâce à des
                      exercices de visualisations et de respirations. N’attendez
                      plus pour vous faire aider !
                    </p>
                  </div>

                  <div>
                    <h3>Préparation à l’accouchement :</h3>
                    <p>
                      Vous êtes enceinte et vous souhaitez être accompagnée en
                      douceur durant votre grossesse. La sophrologie peut vous
                      aider à gérer les émotions et les changements que votre
                      corps et votre esprit subit durant la grossesse. Votre
                      sophrologue vous donnera des techniques de respirations et
                      relaxations à utiliser durant la grossesse et
                      l’accouchement. Bien entendu cela ne dispense pas de
                      l’accompagnement médical. Si vous le souhaitez, nous
                      pouvons établir un tarif forfaitaire.
                    </p>
                  </div>

                  <div>
                    <h3>
                      Accompagnement et préparation d’un projet ou d’un
                      événement (examen, compétition …) :
                    </h3>
                    <p>
                      Vous avez un projet de vie, un examen ou une compétition à
                      venir et vous souhaitez vous faire accompagner afin de
                      donner le meilleur de vous-même. La sophrologie peut vous
                      accompagner dans vos projets à travers des séances
                      personnalisées où votre sophrologue vous guidera à travers
                      des visualisations et des méthodes de relaxations pour que
                      vous puissiez trouver l’énergie afin de mener à bien votre
                      objectif.
                    </p>
                  </div>

                  <div>
                    <h3>
                      Haut Potentiel Sensible :
                    </h3>
                    <p>
                      L'hypersensibilité ou Haut Potentiel Sensible est une caractéristique qui s'accompagne de kinesthésies. Les personnes hypersensibles ont un mode de fonctionnement différent et ont une grande capacité à se remettre en question, à écouter et à être touché par son environnement. Sensible aux hyperstimulations, la personne HPS peut être vite fatiguée, voir stressée dans son quotidien car elle ne connait pas ses particularités. La sophrologie va lui donner des outils et exercices personnalisés à ses besoins, faciles et rapides à mettre en place dans son quotidien. Vous vous posez des questions et souhaitez valider potentiellement la piste du HPS chez vous ou votre enfant ? Passez le test du Dr Elaine Aron.
                    </p>
                  </div>

                  <div>
                    <h3>
                      Accompagnement dans les soins (douleur, cicatrisation,
                      rééducation) :
                    </h3>
                    <p>
                      Étant infirmière en France, votre sophrologue connaît bien
                      le milieu des soins. En effet, lorsque vous êtes confronté
                      à la douleur d’un soin, à une opération ou une
                      immobilisation, la sophrologie peut vous aider à gérer la
                      douleur. Ou encore, par le biais de séances de
                      visualisations vous pourrez aider votre corps à récupérer
                      plus rapidement.
                    </p>
                  </div>
                </template>
                <template slot="tab-pane-2">
                  <div>
                    <h3>Accompagner l'enfant dans ses émotions :</h3>
                    <p>
                      Tempêtes émotionnelles, fatigue, cris ou pleurs intenses, les émotions ne sont pas faciles à accueillir tant pour les enfants que pour les parents. C’est pourquoi, la sophrologie peut les aider à faire face à ces difficultés. Cela se déroule sous forme d’exercices ludiques qui lui donneront des outils pour pouvoir identifier l’émotion sur le moment et ainsi pouvoir l'accueillir et la vivre en toute sérénité. En tant que parents vous recevrez également des conseils et outils pour accompagner votre enfant dans le développement de son intelligence émotionnelle.
                    </p>
                  </div>

                  <div>
                    <h3>TDA-H :</h3>
                    <p>
                      La sophrologie peut aider à gérer plusieurs aspects de ces
                      troubles. En effet, grâce à des exercices ludiques la
                      sophrologie va pouvoir aider votre enfant à se poser, se
                      calmer. Mais aussi à savoir identifier les sources de
                      stress et à les exprimer. Et surtout à reprendre confiance
                      en lui, car les enfants ayant des TDA-H peuvent vite
                      perdre de l’estime de soi.
                    </p>
                  </div>
                  <div>
                    <h3>Autisme :</h3>
                    <p>
                      La sophrologie peut être adaptée à l’enfant autiste si
                      celui-ci peut communiquer verbalement avec son entourage.
                      En effet, la sophrologie va pouvoir aider l’enfant à se
                      détendre, à prendre conscience de son corps et de ses
                      émotions. Bien entendu, l’évolution se fera
                      progressivement au rythme de l’enfant et selon ses
                      besoins. La sophrologie s’adapte toujours au patient pour
                      une meilleure prise en charge.
                    </p>
                  </div>

                  <div>
                    <h3>
                      Haut Potentiel Sensible :
                    </h3>
                    <p>
                      L'hypersensibilité ou Haut Potentiel Sensible est une caractéristique qui s'accompagne de kinesthésies. Les personnes hypersensibles ont un mode de fonctionnement différent et ont une grande capacité à se remettre en question, à écouter et à être touché par son environnement. Sensible aux hyperstimulations, la personne HPS peut être vite fatiguée, voir stressée dans son quotidien car elle ne connait pas ses particularités. La sophrologie va lui donner des outils et exercices personnalisés à ses besoins, faciles et rapides à mettre en place dans son quotidien. Vous vous posez des questions et souhaitez valider potentiellement la piste du HPS chez vous ou votre enfant ? Passez le test du Dr Elaine Aron.
                    </p>
                  </div>
                </template>

                <template slot="tab-pane-3">
                  <p>
                    Les séances ou les ateliers peuvent être tournés sur
                    différentes problématiques comme le stress au travail, la
                    relation de confiance et travail d’équipe ou encore sur la
                    concentration. Il n’y a pas besoin d’attendre que le
                    problème soit installé pour agir en prévention.
                  </p>

                  <div>
                    <h3>Stress au travail :</h3>
                    <p>
                      En effet, beaucoup de personnes sont concernées par le
                      stress au travail, proposer des séances de sophrologie au
                      sein de votre entreprise permettra de réduire et d’aider
                      vos employé-es à avoir les outils pour mieux gérer les
                      situations stressantes (ex : projet important, prise de
                      parole devant plusieurs personnes, mise en production …)
                    </p>
                  </div>

                  <div>
                    <h3>Relation de confiance :</h3>
                    <p>
                      Il est important dans une entreprise que règne un climat
                      de confiance entre collègue, ainsi une ambiance
                      chaleureuse permettra d’éviter ou de réduire le stress et
                      favorisera une communication pertinente entre chaque
                      membre de l’entreprise pour un travail d’équipe efficace.
                    </p>
                  </div>

                  <div>
                    <h3>Concentration :</h3>
                    <p>
                      Stress, fatigue, surmenage sont des facteurs pouvant
                      amener à une difficulté à rester concentré, la sophrologie
                      peut aider à améliorer le quotidien dans votre entreprise
                      via des exercices de respirations adaptés au milieu de
                      l’entreprise. Une meilleure concentration amènera une
                      productivité optimale.
                    </p>
                  </div>

                  <p class="mx-auto mt-3">
                    N’hésitez plus pour vous et votre entreprise contactez-moi
                    par mail ou téléphone pour plus de renseignements.
                  </p>
                </template>
              </tabs>
            </div>
          </div>

          <div class="md-layout">
            <div
              class="md-layout-item md-size-80 md-xsmall-size-100 mx-auto text-center"
            >
              <h3 class="title text-center">
                Libérez-vous de votre passé pour mieux vivre dans le présent et
                préparer votre futur.
              </h3>
            </div>

            <div
              class="md-layout-item md-size-80 md-xsmall-size-100 mx-auto text-center"
            >
              <a
                href="https://squareup.com/appointments/book/jvxl0e0l0mthuc/L0MFD34JZ728M/services"
                target="_blank"
                rel="noopener"
                class="d-block"
              >
                <md-button class="md-success"
                  >Rendez-vous visioconférence</md-button
                >
              </a>
            </div>
            <div
              class="md-layout-item md-size-80 md-xsmall-size-100 mx-auto text-center"
            >
              <div
                class="md-layout-item md-size-80 md-xsmall-size-100 mx-auto text-center"
              >
                <h3 class="title text-center">
                  Inscription à la newsletter
                </h3>
              </div>
              <div
                class="md-layout-item md-size-80 md-xsmall-size-100 mx-auto text-center"
              >
                <a
                  href="https://clemence.podia.com/"
                  target="_blank"
                  rel="noopener"
                  class="d-block"
                >
                  <md-button class="md-info"
                    >S'incrire</md-button
                  >
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div>
      <md-dialog :md-active.sync="showDialog">
        <md-dialog-title style="display: flex; justify-content: space-between;">
          <span>Information importante</span>
          <button class="md-button md-default md-lg" @click="closeModal()">Fermer</button>
        </md-dialog-title>
        <div class="md-layout-item md-size-100 mx-auto">
          <div class="text-center">
            <p style="padding: 30px 0;">
              À compter du 1er mai 2023 les consultations seront fermées pour congé maternité, inscrivez-vous à mon <a href="https://sophrologie-montreal.us19.list-manage.com/subscribe/post?u=0ea04bfd93a95b609813f788c&id=e12e90b617" target="_blank" rel="noopener noreferer">infolettre</a> pour être tenu au courant de la reprise de celles-ci ou contactez-moi par <a href="mailto:clemence.sophrologie@gmail.com" target="_blank" rel="noopener">email</a>.
            </p>
          </div>
        </div>
      </md-dialog>
    </div> -->
  </div>
</template>

<script>
import { NavTabsCard } from "@/components";
import { Tabs } from "@/components";
export default {
  components: {
    NavTabsCard,
    Tabs,
  },
  name: "index",
  bodyClass: "index-page",
  metaInfo: {
    title: "Clémence Bauche - Sophrologue méthode caycedienne",
    meta: [
      {
        name: "description",
        content:
          "Sophrologue méthode caycedienne - Pratiquer la sophrologie vous aidera à mieux vous connaitre pour mieux vivre en accord avec vos valeurs",
      },
    ],
    link: [{ rel: "canonical", href: "https://clemence-sophrologie.fr/" }],
  },
  props: {
    image: {
      type: String,
      default: require("@/assets/img/vue-mk-header_2.jpg"),
    },
    leaf4: {
      type: String,
      default: require("@/assets/img/leaf4_2.png"),
    },
    leaf3: {
      type: String,
      default: require("@/assets/img/leaf3_2.png"),
    },
    leaf2: {
      type: String,
      default: require("@/assets/img/leaf2_2.png"),
    },
    leaf1: {
      type: String,
      default: require("@/assets/img/leaf1_2.png"),
    },
  },
  data() {
    return {
      firstname: null,
      email: null,
      name: null,
      message: null,
      // password: null,
      leafShow: false,
      // showDialog: false,
    };
  },
  methods: {
    leafActive() {
      if (window.innerWidth < 769) {
        this.leafShow = false;
      } else {
        this.leafShow = true;
      }
    },
    // closeModal() {
    //   this.showDialog = false;
    // },
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.image})`,
      };
    },
  },
  mounted() {
    this.leafActive();
    window.addEventListener("resize", this.leafActive);
    // this.showDialog = false;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.leafActive);
  },
};
</script>

<style lang="scss" scoped>
.md-dialog /deep/.md-dialog-container {
  max-width: 768px;
}
.md-dialog-title.md-title {
  background-color: #7cb342;
  padding: 20px;
  color: white;
}
.md-dialog-container {
  border-radius: 15px;
  padding: 14px;
}
</style>
